import { Text } from "@chakra-ui/react"
import React, { useState } from "react"
import { AccountEdit } from "../forms/account_edit/account_edit"
import { CompanyEdit } from "../forms/company_edit/company_edit"
import { InterviewList } from "./interview_list"
import { HomeMenu } from "./menu"

function renderSection(section: string) {
  switch (section) {
    case "Interviews":
      return <InterviewList />
    case "Company":
      return <CompanyEdit />
    case "Settings":
      return <AccountEdit />
    default:
      return <Text>Default</Text>
  }
}

export const Home = () => {
  const [section, setSection] = useState("Interviews")

  return <HomeMenu setSection={setSection}>{renderSection(section)}</HomeMenu>
}
