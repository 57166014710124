import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react"
import React, { useContext, useState } from "react"
import { sendAmplitudeEvent } from "../../../services/amplitude"
import { AccountUpdateRequest, AnalyticsEvents } from "../../../services/types"
import { ApiContext, UserContext } from "../../Root"

export const AccountEdit = () => {
  const user = useContext(UserContext)
  const api = useContext(ApiContext)
  const [firstName, setFirstName] = useState(user.data.first_name)
  const [lastName, setLastName] = useState(user.data.last_name)

  async function handleSave() {
    const updateRequest: AccountUpdateRequest = {}
    if (firstName !== user.data.first_name) {
      updateRequest.first_name = firstName
    }
    if (lastName !== user.data.last_name) {
      updateRequest.last_name = lastName
    }
    if (Object.keys(updateRequest).length < 1) {
      return
    }
    sendAmplitudeEvent(AnalyticsEvents.settings_tab_account_update_attempted, {
      first_name: updateRequest.first_name,
      last_name: updateRequest.last_name,
    })

    const didUpdateAccount = await api.client.updateAccount(updateRequest)
    if (!didUpdateAccount.success) {
      sendAmplitudeEvent(AnalyticsEvents.settings_tab_account_update_failed, {
        first_name: updateRequest.first_name,
        last_name: updateRequest.last_name,
      })
      alert(didUpdateAccount.message)
      return
    }
    sendAmplitudeEvent(AnalyticsEvents.settings_tab_account_update_succeeded, {
      first_name: updateRequest.first_name,
      last_name: updateRequest.last_name,
    })
    const updatedAccount = await api.client.getAccount()
    if (!updatedAccount || Object.keys(updatedAccount).length < 1) {
      return
    }
    user.setUser(updatedAccount)
  }

  return (
    <Container borderRadius={"md"} bgColor="white" py={{ base: "4", md: "8" }}>
      <Stack spacing="5">
        <Stack
          spacing="4"
          direction={{ base: "column", sm: "row" }}
          justify="space-between"
        >
          <Box>
            <Text fontSize="xl" fontWeight="medium">
              {`Edit Account Details`}
            </Text>
          </Box>
        </Stack>
        <Divider borderWidth={2} />
        <Stack spacing="5" divider={<StackDivider />}>
          <FormControl id="first_name">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "10" }}
              justify="space-between"
            >
              <FormLabel variant="inline">First name</FormLabel>
              <Input
                variant="flushed"
                maxW={{ md: "3xl" }}
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
              />
            </Stack>
          </FormControl>
          <FormControl id="last_name">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "10" }}
              justify="space-between"
            >
              <FormLabel variant="inline">Last name</FormLabel>
              <InputGroup maxW={{ md: "3xl" }}>
                <Input
                  variant="flushed"
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                />
              </InputGroup>
            </Stack>
          </FormControl>
          <FormControl id="email">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "8" }}
              justify="space-between"
            >
              <FormLabel variant="inline">Email</FormLabel>
              <Text>{user.data.email}</Text>
            </Stack>
          </FormControl>
          <Button
            onClick={handleSave}
            width="100%"
            height={12}
            fontSize="20px"
            colorScheme={"blue"}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Container>
  )
}
