import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  Icon,
  Select,
  SimpleGrid,
  Spacer,
  Tag,
  TagLabel,
  VStack,
} from "@chakra-ui/react"
import { navigate } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import { FaRegBuilding } from "react-icons/fa"
import { BsPlusCircle } from "react-icons/bs"
import {
  AnalyticsEvents,
  EnrichedInterviewData,
  InterviewParticipant,
} from "../../services/types"
import { LANGUAGES } from "../../utils/languages"
import { ApiContext, CompanyContext } from "../Root"
import { COLORS_PRIMARY } from "../shared/vomux_colors"
import { sendAmplitudeEvent } from "../../services/amplitude"
import { getUuidFromPath } from "../../utils/path"

interface InterviewCardProps {
  interviewId: string
  language: string
  participants?: InterviewParticipant[]
  company_name?: string
}

function InterviewCardWrapper({ children }) {
  return (
    <Box
      borderRadius={"lg"}
      w={{ base: 48, md: 56 }}
      h={{ base: 44, md: 44 }}
      bg={COLORS_PRIMARY.LIGHT_BLUE}
    >
      {children}
    </Box>
  )
}

function CreateInterviewCard() {
  const api = useContext(ApiContext)
  const company = useContext(CompanyContext)
  return (
    <InterviewCardWrapper>
      <Center
        onClick={async () => {
          sendAmplitudeEvent(
            AnalyticsEvents.interview_list_tab_created_interview,
            {
              company_id: company.data.company_id,
              language: LANGUAGES.javascript,
            }
          )
          const interview = await api.client.createInterview({
            company_id: company.data.company_id,
            language: "javascript",
          })
          if (!interview.created_id) {
            alert("Unable to create an interview.")
            return
          }
          navigate(`/interview/${interview.created_id}`)
        }}
      >
        <Icon cursor={"pointer"} w={14} h={14} mt={14} as={BsPlusCircle} />
      </Center>
    </InterviewCardWrapper>
  )
}

function InterviewCard(props: InterviewCardProps) {
  const api = useContext(ApiContext)
  async function changeLanguage(e: React.ChangeEvent<HTMLSelectElement>) {
    sendAmplitudeEvent(AnalyticsEvents.interview_list_tab_changed_language, {
      session_id: getUuidFromPath(),
      language: e.target.value,
    })
    await api.client.updateInterview(props.interviewId, {
      language: e.target.value,
    })
  }
  function renderTag() {
    if (props.participants?.length > 0) {
      return (
        <Flex w="100%" direction={"row"} mt={4} mb={4}>
          <Spacer />
          <Tag mr={8} size="lg" colorScheme="blue" borderRadius="full">
            <Avatar
              size="xs"
              bgColor={COLORS_PRIMARY.DARK_BLUE}
              color="white"
              name={`${props.participants[0].first_name} ${props.participants[0].last_name}`}
              ml={-1}
              mr={2}
            />
            <TagLabel>{props.participants[0].first_name}</TagLabel>
          </Tag>
        </Flex>
      )
    }
    if (props.company_name) {
      return (
        <Flex w="100%" direction={"row"} mt={4} mb={4}>
          <Spacer />
          <Tag mr={2} size="lg" colorScheme="blue" borderRadius="full">
            <Icon ml={-1} mr={2} as={FaRegBuilding} />
            <TagLabel>{props.company_name}</TagLabel>
          </Tag>
        </Flex>
      )
    }
    return (
      <Flex w="100%" direction={"row"} mt={4} mb={4}>
        <Spacer />
        <Tag mr={6} size="lg" colorScheme="blue" borderRadius="full">
          <TagLabel>Empty</TagLabel>
        </Tag>
      </Flex>
    )
  }
  return (
    <InterviewCardWrapper>
      <VStack>
        {renderTag()}
        <Select
          defaultValue={props.language}
          onChange={changeLanguage}
          bg="white"
          w={{ base: 32, md: 40 }}
        >
          {Object.keys(LANGUAGES).map(key => {
            return <option value={key}>{LANGUAGES[key]}</option>
          })}
        </Select>
        <Button
          onClick={() => {
            sendAmplitudeEvent(
              AnalyticsEvents.interview_list_tab_clicked_interview,
              {
                interview_id: props.interviewId,
              }
            )
            navigate(`/interview/${props.interviewId}`)
          }}
          w={{ base: 32, md: 40 }}
        >
          Enter
        </Button>
      </VStack>
    </InterviewCardWrapper>
  )
}

function SimpleInterviewList(props) {
  return (
    <SimpleGrid
      mt={{ base: 4, md: 3 }}
      spacing={{ base: 8, md: 16 }}
      columns={{ base: 1, md: 3 }}
    >
      {props.createCard ? props.createCard : undefined}
      {props.interviews.map(interview => {
        const interviewCardProps: InterviewCardProps = {
          interviewId: interview.interview_id,
          language: interview.language,
        }
        if (interview.participants) {
          interviewCardProps.participants = interview.participants
        }
        if (interview.company_name) {
          interviewCardProps.company_name = interview.company_name
        }
        return <InterviewCard {...interviewCardProps} />
      })}
    </SimpleGrid>
  )
}

export function InterviewList() {
  const api = useContext(ApiContext)
  const company = useContext(CompanyContext)
  const [interviews, setInterviews] = useState<EnrichedInterviewData[]>([])
  const [sessionInterviews, setSessionInterviews] = useState<
    EnrichedInterviewData[]
  >([])

  async function setupSessionInterviews() {
    const sessionInvitesRes = await api.client.getInterviewsBySessionInvite()
    if (sessionInvitesRes && sessionInvitesRes.length > 0) {
      setSessionInterviews(sessionInvitesRes)
    }
  }
  async function setupCompanyInterviews() {
    let companyId = company?.data?.company_id
    let companyData
    if (!companyId && typeof window !== "undefined") {
      try {
        companyData = JSON.parse(localStorage.getItem("company"))
      } catch (e) {}

      if (!companyData) {
        return
      }
      companyId = companyData.company_id
    }
    const interviewsRes = await api.client.getInterviewsForCompany(companyId)
    if (!interviewsRes || !interviewsRes.length || interviewsRes.length < 1) {
      alert("Issue fetching interviews")
      return
    }
    const interviewParticipantsMap = await api.client.getInterviewParticipants(
      companyId
    )
    if (!interviewParticipantsMap) {
      setInterviews(interviewsRes)
    }
    setInterviews(
      interviewsRes.map(interviewRes => {
        return {
          ...interviewRes,
          participants: interviewParticipantsMap[interviewRes.interview_id],
        }
      })
    )
  }

  async function ue() {
    await setupCompanyInterviews()
    await setupSessionInterviews()
  }
  useEffect(() => {
    ue()
  }, [api.client.token, company.data.company_id])

  return (
    <VStack overflowY="scroll">
      {interviews.length > 0 ? (
        <SimpleInterviewList
          title={`${company.data.company_name} Interviews`}
          interviews={interviews}
          createCard={
            <CreateInterviewCard
              api={api}
              companyId={company.data.company_id}
            />
          }
        />
      ) : undefined}
      {sessionInterviews.length > 0 && interviews.length < 1 ? (
        <SimpleInterviewList
          title="Your Interviews"
          interviews={sessionInterviews}
        />
      ) : undefined}
    </VStack>
  )
}
