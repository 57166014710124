import { Box } from "@chakra-ui/react"
import React from "react"
import { Home } from "../components/home/home"
import Seo from "../components/seo"
import { useDelayedAnalyticsEventOnLand } from "../hooks/analytics"
import { AnalyticsEvents } from "../services/types"

const HomePage = () => {
  useDelayedAnalyticsEventOnLand(AnalyticsEvents.home_landed)
  return (
    <Box>
      <Seo title="Home" />
      <Home />
    </Box>
  )
}

export default HomePage
