import React, { ReactNode, useContext, useEffect, useState } from "react"
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Img,
} from "@chakra-ui/react"
import { FiMenu } from "react-icons/fi"
import { MdLogout, MdPeople, MdSettings } from "react-icons/md"
import { FaRegBuilding } from "react-icons/fa"
import { IconType } from "react-icons"
import { ReactText } from "react"
import vomuxLogo from "../../images/vomux_logo.svg"
import { navigate } from "gatsby"
import { COLORS_PRIMARY } from "../shared/vomux_colors"
import { CompanyContext } from "../Root"
import { sendAmplitudeEvent } from "../../services/amplitude"
import { AnalyticsEvents } from "../../services/types"

interface LinkItemProps {
  name: string
  icon: IconType
}
const LinkItems: Array<LinkItemProps> = [
  { name: "Interviews", icon: MdPeople },
  { name: "Company", icon: FaRegBuilding },
  { name: "Log Out", icon: MdLogout },
]

interface HomeMenuProps {
  chilren: ReactNode
  setSection: Function
}

export function HomeMenu({
  children,
  setSection,
}: {
  children: ReactNode
  setSection: Function
}) {
  const company = useContext(CompanyContext)
  const [linkItems, setLinkItems] = useState<LinkItemProps[]>([
    { name: "Interviews", icon: MdPeople },
    { name: "Settings", icon: MdSettings },
    { name: "Log Out", icon: MdLogout },
  ])
  useEffect(() => {
    if (company.data.company_id) {
      setLinkItems([
        { name: "Interviews", icon: MdPeople },
        { name: "Company", icon: FaRegBuilding },
        { name: "Settings", icon: MdSettings },
        { name: "Log Out", icon: MdLogout },
      ])
    }
  }, [company.data.company_id])
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Box
      minH="100vh"
      bg={useColorModeValue(COLORS_PRIMARY.BACKGROUND_BLUE, "gray.900")}
    >
      <SidebarContent
        linkItems={linkItems}
        setSection={setSection}
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            linkItems={linkItems}
            setSection={setSection}
            onClose={onClose}
          />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: "flex", md: "none" }} onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </Box>
  )
}

interface SidebarProps extends BoxProps {
  onClose: () => void
  setSection: Function
  linkItems: LinkItemProps[]
}

const SidebarContent = ({
  onClose,
  linkItems,
  setSection,
  ...rest
}: SidebarProps) => {
  return (
    <Box
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Img mt="1rem" h="40px" src={vomuxLogo} />
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {linkItems.map(link => (
        <NavItem
          onClick={e => {
            sendAmplitudeEvent(AnalyticsEvents.home_tab_clicked, {
              name: link.name,
            })
            if (link.name === "Log Out") {
              navigate("/logout")
              return
            }
            setSection(link.name)
          }}
          key={link.name}
          icon={link.icon}
        >
          {link.name}
        </NavItem>
      ))}
    </Box>
  )
}

interface NavItemProps extends FlexProps {
  icon: IconType
  children: ReactText
}
const NavItem = ({ icon, children, ...rest }: NavItemProps) => {
  return (
    <Link style={{ textDecoration: "none" }} _focus={{ boxShadow: "none" }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: `${COLORS_PRIMARY.LIGHT_BLUE}`,
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  )
}

interface MobileProps extends FlexProps {
  onOpen: () => void
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />
      <Img mt="1rem" ml="1rem" h="40px" src={vomuxLogo} />
    </Flex>
  )
}
