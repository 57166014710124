import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react"
import React, { useContext, useState } from "react"
import { AnalyticsEvents, CompanyUpdateRequest } from "../../../services/types"
import { CompanySizeSelect } from "../create_company/create_company_form"
import { ApiContext, CompanyContext } from "../../Root"
import { sendAmplitudeEvent } from "../../../services/amplitude"

export const CompanyEdit = () => {
  const company = useContext(CompanyContext)
  const api = useContext(ApiContext)
  const [companyName, setCompanyName] = useState(company.data.company_name)
  const [companySize, setCompanySize] = useState(company.data.company_size)
  const [companyWebsite, setCompanyWebsite] = useState(
    company.data.company_website
  )

  async function handleSave() {
    const updateRequest: CompanyUpdateRequest = {}
    if (companyName !== company.data.company_name) {
      updateRequest.company_name = companyName
    }
    if (companySize !== company.data.company_size) {
      updateRequest.company_size = companySize
    }
    if (companyWebsite !== company.data.company_website) {
      updateRequest.company_website = companyWebsite
    }
    if (Object.keys(updateRequest).length < 1) {
      return
    }

    sendAmplitudeEvent(AnalyticsEvents.company_tab_company_update_attempted, {
      ...updateRequest,
    })
    const updatedCompany = await api.client.updateCompany(
      company.data.company_id,
      updateRequest
    )
    if (!updatedCompany.success) {
      sendAmplitudeEvent(AnalyticsEvents.company_tab_company_update_failed, {
        ...updateRequest,
      })
      alert(updatedCompany.message)
      return
    }
    sendAmplitudeEvent(AnalyticsEvents.company_tab_company_update_succeeded, {
      ...updateRequest,
    })
    const updatedCompanyData = await api.client.getCompanyById(
      company.data.company_id
    )
    if (!updatedCompanyData || Object.keys(updatedCompanyData).length < 1) {
      return
    }
    company.setCompany(updatedCompanyData)
  }

  return (
    <Container borderRadius={"md"} bgColor="white" py={{ base: "4", md: "8" }}>
      <Stack spacing="5">
        <Stack
          spacing="4"
          direction={{ base: "column", sm: "row" }}
          justify="space-between"
        >
          <Box>
            <Text fontSize="xl" fontWeight="medium">
              {`Edit ${company.data?.company_name}`}
            </Text>
          </Box>
        </Stack>
        <Divider borderWidth={2} />
        <Stack spacing="5" divider={<StackDivider />}>
          <FormControl id="company_name">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "10" }}
              justify="space-between"
            >
              <FormLabel variant="inline">Name</FormLabel>
              <Input
                variant="flushed"
                maxW={{ md: "3xl" }}
                value={companyName}
                onChange={e => setCompanyName(e.target.value)}
              />
            </Stack>
          </FormControl>
          <FormControl id="company_size">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "14" }}
              justify="space-between"
            >
              <FormLabel variant="inline">Size</FormLabel>
              <CompanySizeSelect
                defaultValue={companySize}
                onChange={e => setCompanySize(e.target.value)}
              />
            </Stack>
          </FormControl>
          <FormControl id="company_website">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "8" }}
              justify="space-between"
            >
              <FormLabel variant="inline">Website</FormLabel>
              <InputGroup maxW={{ md: "3xl" }}>
                <Input
                  variant="flushed"
                  value={companyWebsite}
                  onChange={e => setCompanyWebsite(e.target.value)}
                />
              </InputGroup>
            </Stack>
          </FormControl>
          <Button
            onClick={handleSave}
            width="100%"
            height={12}
            fontSize="20px"
            colorScheme={"blue"}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Container>
  )
}
